.services__container {
  text-align: center;
}

.servicesItem {
  max-width: 450px;
}

.servicesItem__icon svg{
  width: 3rem;
}

.servicesItem__title {
  font-size: 2.5rem;
  font-family: var(--title-font);
}

.services__container .des__container {
  margin-top: 2rem;
  font-size: 2rem;
}

.services__items {
  padding: 10rem 0rem;
  display: flex;
  gap: 10rem;
  justify-content: space-between;
  margin-top: 5rem;
}

@media only screen and (max-width: 768px) {
  .services__items {
    flex-direction: column;
    max-width: 350px;
    margin: 0 auto;
    margin-top: 5rem;
    gap: 5rem;
  }
}