.timeline {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.item-timeline {
  display: flex;
  align-items: flex-start;
}
.item-timeline:nth-child(even) {
  display: flex;
  flex-direction: row-reverse;
}
.item-timeline > div {
  padding: 50px 40px;
}
.date {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: flex-end;
  text-align: right;
}
.item-timeline:nth-child(even) .date {
  display: flex;
  flex-direction: row-reverse;
}
.date::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -1.1rem;
  transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: var(--highlight-color);
  box-shadow: rgba(160, 249, 255, 0.521) 0 0 10px;
}
.item-timeline:nth-child(even) .date::after {
  left: -1.1rem;
}
.date__badge {
  font-size: 2rem;
  padding: 2rem 3rem;
  border: 2px solid var(--gray-1);
  background-color: var(--highlight-color);
  border-color: var(--highlight-color);
  color: var(--white);
  font-weight: bold;
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23DCDCDD' fill-opacity='0.2'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  animation: animatedBackground 5s linear infinite alternate;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
}
.date__decor {
  position: absolute;
  top: 25%;
  right: 10%;
  height: auto;
  max-width: 400px;
  z-index: -1;
}
.item-timeline:nth-child(odd) .date__decor {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  left: 10%;
}

.content {
  border-left: 2px solid var(--highlight-color);
  flex: 1;
}
.item-timeline:nth-child(even) .content {
  border-left: 0;
  border-right: 2px solid var(--highlight-color);
  text-align: right;
}
.content__job {
  margin-top: 2.5rem;
  margin-bottom: 0.5rem;
  font-size: 3rem;
}
.content__company {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: var(--highlight-color);
  text-shadow: var(--highlight-text-shadow);
}

.content-description {
  padding: 2rem 2rem;
  color: var(--light-gray);
  font-size: 1.5rem;
  line-height: 1.6;
  background: var(--card-gradient);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
}
.item-timeline:nth-child(even) .content-description {
  text-align: left;
}
.content-description strong {
  color: var(--white);
  opacity: 0.8;
  letter-spacing: 0.05rem;
}
.content-description__list {
  margin-left: 2rem;
}
.content-description__item {
  list-style-type: square;
}
.content-description__item:not(:last-child) {
  margin-bottom: 1rem;
}
@media only screen and (max-width: 991px) {
  .item-timeline,
  .item-timeline:nth-child(even) {
    flex-direction: column;
  }
  .item-timeline > div {
    padding: 20px 0 20px 30px;
  }
  .item-timeline > div:last-child {
    padding: 20px 0 0px 30px;
  }
  .item-timeline:nth-child(even) .content {
    border-right: 0;
    border-left: 2px solid var(--highlight-color);
    text-align: left;
  }
  .item-timeline .item-timeline__content.content {
    padding-top: 0;
    padding-bottom: 8rem;
  }
  .item-timeline .item-timeline__date.date {
    border-left: 2px solid var(--highlight-color);
  }
  .item-timeline:first-child .date {
    padding-top: 0;
  }
  .date__decor {
    display: none;
  }
  .item-timeline:nth-child(odd) .date::after {
    left: -1.1rem;
    transform: translateY(-100%);
  }
  .content__job {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-size: 2.8rem;
  }
}
@media only screen and (max-width: 768px) {
  .date {
    width: 100%;
  }
  .date__badge {
    width: 100%;
    text-align: center;
  }
  .content__job {
    margin-bottom: 0.3rem;
    font-size: 2.4rem;
  }
  .content__company {
    margin-top: 0;
    margin-bottom: 1.2rem;
    font-size: 2rem;
  }
  .content-description {
    font-size: 1.4rem;
    padding: 2rem 1.5rem;
  }
}
@media only screen and (max-width: 479px) {
  .item-timeline > div:last-child {
    padding: 0px 0 0px 20px;
  }
  .item-timeline > div {
    padding: 20px 0 20px 20px;
  }
  .content-description__list {
    margin-left: 0;
  }
  .content-description__item {
    list-style-type: none;
  }
}
