/* Basic styles */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:root {
  /* Palette */
  --black: black;
  --white: white;
  --deep-dark: #1e1e1e;
  --dark-bg: #46494c;
  --gray-2: #4c5c68;
  --gray-1: #c6c4c3;
  --light-gray: #dcdcdd;
  --highlight-color: #1985a1;
  --highlight-2-color: #fa8c43;
  --success-color: rgb(41, 253, 115);
  --failure-color: rgb(255, 129, 162);
  --highlight-text-shadow: rgba(8, 239, 255, 0.457) 0 0 5px;
  --dark-text-shadow: rgba(0, 0, 0, 0.2) 0 0 5px;
  --background-gradient: linear-gradient(
    90deg,
    rgb(45, 45, 49) 40%,
    rgb(49, 62, 71) 100%
  );
  --card-gradient: linear-gradient(135deg, rgb(111, 145, 158), hsl(0, 0%, 25%));
}
html {
  font-size: 10px;
  font-family: "Roboto Mono";
  background: var(--background-gradient);
}
#root {
  overflow-x: hidden;
}
ul,
li {
  list-style: none;
}
a {
  text-decoration: none;
}
img,
svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
button {
  outline: none;
  cursor: pointer;
}
.container {
  max-width: 1100px;
  width: 90%;
  margin-inline: auto;
  /* width: min(90%, 70.5rem) */
  /* margin: 0 auto; */
}

/* Card styles */
.card {
  background-color: var(--dark-bg);
  background-image: var(--card-gradient);
  transition: 0.5s;
}

.card:hover {
  background-color: var(--black);
}

/* Typography */
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@600;700&family=Roboto+Flex:opsz@8..144&display=swap");

:root {
  --main-font: "Roboto Flex", sans-serif;
  --title-font: "Prompt", sans-serif;
}

html {
  font-family: var(--main-font);
  color: var(--gray-1);
}
* {
  font-family: var(--main-font);
  color: var(--gray-1);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--title-font);
}

::-webkit-scrollbar {
  width: 1.5em;
  height: 1.5em;
}

/* ScrollBar Styles */

/* Chrome */
::-webkit-scrollbar-track {
  background: var(--dark-bg);

  /*border-radius: 100vw;*/
  margin-block: 0.5em;
}
::-webkit-scrollbar-thumb {
  background-color: var(--gray-1);
  background-image: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.1),
    hsla(0, 0%, 0%, 0.397)
  );
  border: 0.25em solid var(--gray-1);
  /*border-radius: 100vw;*/
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--highlight-color);
}

/* FireFox */
@supports (scrollbar-color: var(--gray-1) var(--dark-bg)) {
  * {
    scrollbar-color: var(--gray-1) var(--dark-bg);
    scrollbar-width: auto;
  }
}
