@keyframes jump {
  from {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(0px);
  }
}

.hero {
  display: flex;
  align-items: center;
  height: 100vh;
  min-height: 800px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  position: relative;
}

.hero__container {
  margin-top: 2rem;
}

.hero .container {
  padding: 2rem 1rem;
}

.hero__heading {
  padding-top: 1.5rem;
  font-size: 2.5rem;
  margin-bottom: -6rem;
  position: relative;
}

.hero__heading span {
  display: inline-block;
  width: 100%;
}

.hero__name {
  font-size: 6.5rem;
  font-family: "Prompt", sans-serif;
  color: var(--white);
}

.hero__img {
  transition: all 1s ease 0s;
  max-width: 600px;
  width: 100%;
  /* height: 600px; */
  margin: 0 auto;
  border-radius: 50%;
  background: linear-gradient(
    194deg,
    rgba(25, 134, 161, 0.27) 10%,
    rgba(0, 0, 0, 0) 90%
  );
  background-color: var(--highlight-color);
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23DCDCDD' fill-opacity='0.2'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  animation: animatedBackground 20s linear infinite alternate;
}
.hero__container {
  transition: opacity 2s ease 0s;
}
.hero__container--unloaded {
  opacity: 0;
}

@media only screen and (max-width: 1560px) {
  .hero__img {
    max-width: 400px;
  }
  .hero {
    min-height: auto;
  }
}

.hero__img img {
  height: auto;
  max-width: 600px;
}

.hero__info {
  padding-top: 1rem;
}
.hero__info .button {
  margin-top: 2rem;
}
.hero__scrollDown {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 50px;
}

.hero__scrollDown {
  position: absolute;
  right: 50px;
  bottom: 20px;
}

.hero__scrollDown {
  width: 50px;
}

@media only screen and (max-width: 1340px) {
  .hero__scrollDown {
    right: 0px;
  }
}

.hero__scrollDown p {
  font-size: 1.6rem;
  transform: translateY(-70px) rotate(90deg);
  letter-spacing: 0.7rem;
  text-transform: uppercase;
}

.hero__scrollDown svg {
  max-height: 45px;
  width: 20px;
  margin: 0 auto;
  object-fit: contain;
}
.hero__scrollDown svg {
  transition: 1s;
  animation: jump 1s linear infinite alternate;
}

@media only screen and (max-width: 768px) {
  .hero {
    height: 100vh;
    /* padding-top: 2rem; */
    min-height: 750px;
  }
  .hero__heading {
    font-size: 2rem;
    margin-bottom: -2rem;
  }
  .hero__name {
    font-size: 5.5rem;
  }

  .hero__img {
    /* ??? */
    height: auto;
    /* height: 300px; */
  }
  .hero__info {
    margin-top: 3rem;
  }
  .hero__scrollDown p {
    font-size: 1.2rem;
  }
  .hero__scrollDown svg {
    max-height: 22px;
  }
  .hero__scrollDown {
    right: 0;
    width: 20px;
    gap: 1rem;
  }
}
