.projects__container {
  padding: 10rem 0;
  transition: opacity 2s ease 0s;
}
.projects__container--unloaded {
  opacity: 0;
}
.projects__items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 5rem 0;
  gap: 5rem;
}
.project__container {
  flex: 0 1 47%;
  min-height: 280px;
  -webkit-perspective: 1500px;
  -moz-perspective: 1500px;
  -o-perspective: 1500px;
  -ms-perspective: 1500px;
  perspective: 1500px;
  transition: all 0.3s ease 0s;
}
.project.flipped {
  cursor: pointer;
  transform: rotateY(180deg);
}

.project {
  z-index: 20;
  position: relative;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease 0s;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
}
.project__container:hover {
  transform: scale(1.03);
}
.project__container:hover .project {
  border: 1px solid var(--highlight-color);
  box-shadow: 0 0 10px 5px rgba(49, 158, 154, 0.1);
}
.project__container:hover .project__front {
  background-color: rgba(7, 7, 7, 0.8);
}
.project__container:hover .project__info {
  backdrop-filter: blur(2px);
}
.project__front,
.project__back {
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-perspective: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  visibility: visible;
  backface-visibility: hidden;
  transition: all 0.3s ease 0s;
}
.project__front {
  background-color: rgba(7, 7, 7, 0.9);
}
.project__back {
  transform: rotateY(180deg);
}
.project__info {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  padding: 4rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.project__front img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
  overflow: hidden;
  filter: brightness(15%);
}

.project__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.project-info__title {
  font-size: 2rem;
  font-family: var(--title-font);
}
.project-info__type {
  position: absolute;
  top: 5%;
  left: 3%;
  transform: translateY(50%);
  font-size: 1.4rem;
  color: var(--highlight-color);
  text-shadow: var(--highlight-text-shadow);
}

.project-info__des {
  font-size: 1.6rem;
  line-height: 1.4;
  text-align: justify;
  font-family: var(--main-font);
}
@keyframes bounce {
  from {
    transform: scale(1.3);
  }
  to {
    transform: scale(1);
  }
}

.project-info__links {
  z-index: 1;
  position: absolute;
  display: flex;
  gap: 1.2rem;
  top: 5%;
  right: 3%;
}
.project-info__links svg {
  width: 24px;
}
.project__flipper {
  position: absolute;
  width: 45px;
  z-index: 1;
  bottom: 5%;
  right: 3%;
  padding: 0.5rem;
  transition: all 0.3s ease 0s;
  animation: 0.8s infinite ease bounce;
  /*overflow: visible;*/
}
.project__flipper:hover svg {
  transform: scale(1.2);
}
.project__flipper:hover path {
  color: var(--highlight-color);
}
.project-info__btn:hover path {
  color: var(--highlight-color);
}
.project-info__btn:hover svg {
  transform: scale(1.2);
}
.project-info__btn svg,
.project-info__btn path,
.project__flipper path,
.project__flipper svg {
  transition: all 0.3s ease 0s;
}

.project-info__technologies {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  row-gap: 0.3rem;
  flex-wrap: wrap;
  max-width: 250px;
}
.project-info__technology {
  font-size: 1.4rem;
  color: var(--highlight-color);
  text-shadow: var(--highlight-text-shadow);
}
@media only screen and (max-width: 992px) {
  .projects__items {
    justify-content: space-between;
  }
  .project__container {
    flex: 0 1 100%;
  }
  .project-info__title {
    font-size: 2.4rem;
  }
  .project-info__type {
    font-size: 1.8rem;
  }
  .project-info__des {
    line-height: 1.6;
    font-size: 1.8rem;
  }
  .project-info__technologies {
    max-width: 400px;
  }
  .project-info__technology {
    font-size: 1.6rem;
  }
}
@media only screen and (max-width: 767px) {
  .project-info__title {
    margin-top: 1rem;
    font-size: 2.4rem;
  }
  .project-info__type {
    font-size: 1.6rem;
  }
  .project-info__des {
    text-align: center;
    line-height: 1.4;
    font-size: 1.6rem;
  }
  .project-info__technologies {
    max-width: 300px;
  }
  .project-info__technology {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 479px) {
  .project__container {
    min-height: 350px;
  }
  .project__info {
    backdrop-filter: blur(5px);
    padding: 3rem 1.5rem 1.5rem;
  }
  .project-info__title {
    margin-top: 0.5rem;
    font-size: 2.2rem;
  }
  .project-info__links {
    top: 2%;
  }
  .project-info__type {
    top: 2%;
    font-size: 1.4rem;
  }
  .project-info__des {
    line-height: 1.2;
    font-size: 1.6rem;
  }
  .project-info__technologies {
    margin-top: 2rem;
    max-width: 200px;
  }
  .project-info__technology {
    font-size: 1.2rem;
  }
  .project__flipper {
    width: 35px;
    bottom: 5%;
    right: 2%;
    padding: 0.5rem;
  }
}
