.aboutSection__container {
  padding: 10rem 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  text-align: left;
  gap: 4rem;
}

/*.aboutSection__left,
.aboutSection__right {
  flex: 1;
}*/
.aboutSection__left {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;
  /*max-width: 400px;*/
}

.aboutSection__left .title-section {
  text-align: left;
}

.aboutSection__left .title-section__title {
  display: inline-block;
}

.aboutSection__container .des__container {
  margin-top: 5rem;
  margin-left: 0;
  flex: 1 0;
  display: inline-block;
  min-height: 70px;
}

.aboutSection__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  margin-top: 2rem;
}
.aboutSection__buttons .button {
  margin-top: 2rem;
}
.aboutSection__img {
  filter: grayscale(40%) contrast(130%);
  height: 100%;
  max-width: 500px;
  background: linear-gradient(
    194deg,
    rgba(25, 134, 161, 0.27) 10%,
    rgba(0, 0, 0, 0) 90%
  );
  background-color: var(--highlight-color);
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23DCDCDD' fill-opacity='0.2'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  animation: animatedBackground 20s linear infinite alternate;
  position: relative;
  transition: 1s;
}

@media only screen and (max-width: 768px) {
  .aboutSection__container {
    flex-direction: column;
    text-align: center;
  }
  .aboutSection__left,
  .aboutSection__right {
    width: 100%;
  }
  .aboutSection__right {
    margin-top: 3rem;
    padding: 0 1rem;
  }
  .aboutSection__left .title-section {
    text-align: center;
  }

  .aboutSection__container .des__container {
    margin: 0 auto;
    margin-top: 2rem;
  }

  .aboutSection__buttons {
    flex-direction: column;
    gap: 0;
  }
  .aboutSection__buttons .btn-div,
  .aboutSection__buttons a {
    width: 100%;
    text-align: center;
  }
}
