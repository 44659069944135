.contact__wrapper {
  padding: 10rem 0 0;
  transition: opacity 2s ease 0s;
}
.contact__wrapper--unloaded {
  opacity: 0;
}
.contactSection__wrapper {
  margin-top: 5rem;
  margin-bottom: 15rem;
  display: flex;
  gap: 5rem;
  justify-content: space-between;
  position: relative;
}
.contactInfoItem__wrapper {
  padding: 0.8rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  color: var(--highlight-color);
  text-shadow: var(--highlight-text-shadow);
  background: transparent;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0);
}
.contactInfoItem__wrapper .icon {
  padding: 1rem;
  align-items: center;
  justify-content: center;
}
.contactInfoItem__wrapper .info p {
  color: var(--highlight-color);
}
.contactInfoItem__wrapper .icon svg {
  width: 2.5rem;
  text-shadow: var(--highlight-text-shadow);
}
.form__wrapper .form {
  width: 100%;
}
.form-group {
  width: 100%;
  margin-bottom: 2rem;
  position: relative;
}
.form-group label {
  color: var(--gray-2);

  font-size: 1.8rem;
  position: absolute;
  left: 0;
  top: 1rem;
  padding: 1.2rem;
  pointer-events: none;
  transition: all 0.3s ease 0s;
}
.form-group input,
.form-group textarea {
  width: 100%;
  padding: 1.2rem;
  font-size: 2rem;
  color: var(--highlight-color);
  text-shadow: var(--highlight-text-shadow);
  border: none;
  transition: 0.5s;
  outline: none;
  margin-top: 1rem;
  background: var(--background-gradient);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0);
}
.form-group input:focus,
.form-group textarea:focus {
  border: 1px solid var(--highlight-color);
  background-color: var(--deep-dark);
  box-shadow: 0px 0px 3px 3px rgba(15, 118, 179, 0.199);
  outline: none;
}
.form-group input:focus ~ label,
.form-group textarea:focus ~ label {
  transform: translate(10px, -7px);
  font-size: 1.4rem;
  padding: 0 1.5rem;
  background: rgb(47, 48, 50);
  border-left: 1px solid var(--highlight-color);
  border-right: 1px solid var(--highlight-color);
}
.form-group input:valid ~ label,
.form-group textarea:valid ~ label {
  color: var(--highlight-color);
  text-shadow: var(--highlight-text-shadow);
  transform: translate(10px, -7px);
  font-size: 1.4rem;
  padding: 0 1.5rem;
  background: rgb(47, 48, 50);
  border-left: 1px solid var(--highlight-color);
  border-right: 1px solid var(--highlight-color);
}
.form-group input:valid,
.form-group textarea:valid {
  border: 1px solid var(--highlight-color);
}
.form-group textarea {
  min-height: 250px;
  resize: vertical;
}
.form__button[type="submit"] {
  display: block;
  width: 100%;
}
.form__result {
  transition: 0.5s;
  margin-top: 2rem;
  font-size: 2rem;
  color: var(--highlight-color);
}
.contactSection__wrapper .left {
  width: 100%;
  max-width: 500px;
}
.contactSection__wrapper .right {
  width: 100%;
  max-width: 500px;
}
@media only screen and (max-width: 940px) {
  .contactSection__wrapper::after {
    display: none;
  }
  .contactSection__wrapper {
    flex-direction: column;
  }
  .contactSection__wrapper .left,
  .contactSection__wrapper .right {
    max-width: 100%;
  }
}

/* Location */
.map__wrapper {
  min-height: 400px;
  position: relative;
  z-index: 10;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  filter: saturate(0.2) brightness(70%);
  transition: all 0.3s ease 0s;
}

iframe:hover {
  filter: saturate(1) brightness(100%);
}

.map {
  position: relative;
  min-height: 400px;
}

.map__card {
  filter: none;
  position: absolute;
  right: 10%;
  bottom: 10%;
  padding: 2rem;
  max-width: 300px;
  width: 100%;
  z-index: 2;
}

.map__card .des__container p {
  color: var(--white);
}

.map__heading {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.map__card svg {
  width: 30px;
}

.map__link {
  display: block;
  font-size: 1.6rem;
  margin-top: 2rem;
  text-decoration: underline;
}

/* Popup */
.popup {
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 220px;
  padding: 2rem 3rem;
  z-index: 100;
  opacity: 0.9;
  transition: opacity 0.3s ease 0s;
  box-shadow: 0 0 10px 5px rgba(49, 158, 154, 0.1);
  background-color: var(--highlight-color);
  border-color: var(--highlight-color);
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23DCDCDD' fill-opacity='0.2'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  animation: animatedBackground 5s linear infinite alternate;
}
.popup::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 3%;
  right: 3%;
  border: 2px solid var(--light-gray);
  z-index: -1;
  opacity: 0.5;
}
.popup.hidden {
  opacity: 0;
  z-index: -100;
  pointer-events: none;
}
.popup__content {
  text-align: center;
  color: var(--success-color);
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 1.1px;
  line-height: 2;
}
.popup__content--success {
  color: var(--success-color);
}
.popup__content--failure {
  color: var(--failure-color);
}

@media only screen and (max-width: 768px) {
  .map__wrapper {
    background-position: 80% center;
  }
}
@media only screen and (max-width: 400px) {
  .map__card {
    max-width: none;
    right: auto;
  }
}
