.socials {
}
.socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 50px;
  z-index: 1;
  position: fixed;
  left: 50px;
  bottom: 70px;
}
.socials::after {
  content: "";
  position: absolute;
  top: 93%;
  left: 46%;
  width: 2px;
  height: 100%;
  opacity: 0.5;
  background-color: var(--white);
}
.socials-indicator {
  margin-top: -3rem;
  width: 25px;
}
@media only screen and (max-width: 1340px) {
  .socials {
    left: 0px;
  }
}

.socials p {
  font-size: 1.6rem;
  transform: translateY(-70px) rotate(90deg);
  letter-spacing: 0.7rem;
  text-transform: uppercase;
}

.socials-indicator svg {
  width: 100%;
}
.socials-indicator svg {
  transition: 1s;
  animation: jump 1s linear infinite alternate;
}

.socials-text li {
  margin-bottom: 1rem;
}
.socials-text a {
  display: inline-block;
  font-size: 2.2rem;
}
.socials-text path {
  transition: 0.5s;
}

.socials-text li:hover path {
  color: var(--highlight-color);
}

@media only screen and (max-width: 768px) {
  .socials {
    left: 0px;
    bottom: 10%;
    width: 20px;
  }
  .socials-indicator {
    width: 20px;
  }
  .socials-indicator p {
    font-size: 1.2rem;
  }
  .socials-indicator svg {
    max-height: 22px;
  }
  .socials-text li {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}
