.technologies {
  padding: 10rem 0 0;
}
.technologies__wrapper {
  padding: 10rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 5rem;
}
.item-technology {
  flex: 0 1 9%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-technology__content {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  position: relative;
  border-radius: 5px;
  transition: all 0.3s ease 0s;
  background-color: var(--highlight-color);
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23DCDCDD' fill-opacity='0.2'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  animation: animatedBackground 5s linear infinite alternate;
}
.item-technology:hover .item-technology__content {
  background-color: var(--white);
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%231985A1' fill-opacity='0.2'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.item-technology__heading {
  font-size: 1.3rem;
  position: absolute;
  color: var(--highlight-color);
  text-shadow: var(--highlight-text-shadow);
  bottom: -20%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  z-index: 2;
  transition: all 0.3s ease 0s;
  backdrop-filter: blur(2px);
}
.item-technology:hover {
  transform: scale(1.2);
}
.item-technology:hover .item-technology__heading {
  padding: 1.3rem;
  font-size: 1.5rem;
}
.item-technology__image {
  filter: drop-shadow(0px 0px 7px rgb(0 0 0 / 0.7));
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .technologies__wrapper {
    padding: 7rem 0;
    gap: 3.5rem;
  }
  .item-technology {
    flex: 0 1 12%;
  }
  .item-technology:hover .item-technology__heading {
    padding: 1rem 1.2rem;
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 768px) {
  .technologies__wrapper {
    padding: 5rem 0;
    column-gap: 2rem;
    row-gap: 5rem;
  }
  .item-technology {
    flex: 0 1 20%;
  }
  .item-technology__heading {
    font-size: 1.8rem;
    bottom: -15%;
    left: 50%;
    padding: 1rem 1.2rem;
  }
}
@media only screen and (max-width: 479px) {
  .technologies__wrapper {
    column-gap: 2.5rem;
    row-gap: 3rem;
  }
  .item-technology {
    flex: 0 1 18%;
  }
  .item-technology:last-child {
    flex-grow: 0;
  }
  .item-technology__heading {
    font-size: 1.5rem;
    bottom: -20%;
    left: 50%;
    padding: 0.3rem 0.7rem;
  }
}
