.not-found__container {
  margin-top: 10rem;
  text-align: center;
}


.not-found__container svg {
  max-width: 600px;
  margin-top: 4rem;
  margin-bottom: 4rem;
}