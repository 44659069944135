.navBar {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1.8rem 0;
  opacity: 0.9;
  transition: background 0.3s ease 0s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.navBar.scrolled {
  background: rgba(36, 36, 36, 0.7);
  backdrop-filter: blur(40px);
}
.navBar__logo {
  flex: 0 1 33.333%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navBar__logo img {
  max-width: 35px;
}

.navBar__list {
  opacity: 1;
  /*max-width: 1200px;
  margin: 0 auto;
  width: 90%;*/
  text-align: center;
  flex: 0 1 66%;
}
.navBar__action {
  flex: 0 1 33.333%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navBar__item {
  display: inline-block;
  /* border-radius: 8px; */
}

.navBar__item::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: var(--highlight-color);
  transition: 0.3s ease-in;
}

.navBar__item:hover::after {
  width: 100%;
}

.navBar__link {
  display: inline-block;
  font-family: "Roboto Flex", sans-serif;
  padding: 0.5rem 2rem;
  font-size: 2rem;
  color: var(--gray-1);
  outline: none;
}

.active {
  color: var(--white);
}

.navBar__menu {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 4rem;
  cursor: pointer;
  display: none;
  outline: none;
}

.navBar__menu * {
  pointer-events: none;
}

.navBar__close {
  display: none;
}

@media only screen and (max-width: 810px) {
  .navBar__logo,
  .navBar__action {
    display: none;
  }
  .navBar {
    padding: 0;
  }
  .hidden {
    transform: translateY(calc(-100% - var(--top)));
  }

  .navBar__menu {
    display: block;
  }

  .navBar__list {
    --top: 1rem;
    top: var(--top);
    padding: 2rem;
    width: 90%;
    max-width: 250px;
    position: absolute;
    right: 1rem;
    transition: 0.3s ease transform;
    background-color: var(--highlight-color);
  }

  .navBar__close {
    display: block;
    width: 3rem;
    margin: 0 0 0 auto;
    cursor: pointer;
  }

  .navBar__close * {
    pointer-events: none;
  }

  .navBar__item {
    display: block;
    margin-bottom: 4rem;
  }

  .navBar__item::after {
    display: none;
  }
}
