.testimonials__container {
  padding: 10rem 0;
  text-align: center;
}

.testimonial__wrapper {
  position: relative;
  max-width: 700px;
  margin: 0 auto;
}

.testimonial__info {
  width: 100%;
  height: fit-content;
  padding: 3rem;
  background-color: var(--dark-bg);
  background-image: var(--card-gradient);
  margin-top: 5rem;
  transition: 0.5s;
}

.testimonial__info:hover {
  background-color: var(--black);
}

.testimonial__des .des__container {
  text-align: center;
}

.testimonial__name {
  margin-top: 4rem;
  font-family: var(--title-font);
  font-size: 2.2rem;
}

.testimonial__title {
  font-size: 1.6rem;
  margin-top: 0.3rem;
  opacity: 0.8;
}

.arrows {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrows svg {
  width: 30px;
  pointer-events: none;
}

.arrow--next,
.arrow--prev {
  margin: 0 0.5rem;
  width: fit-content;
  background-color: var(--dark-bg);
  background-image: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.475),
    hsla(0, 0%, 0%, 0.788)
  );
  padding: 0.5rem 1.6rem;
  cursor: pointer;
  transition: 0.5s;
}

.arrow--next:hover,
.arrow--prev:hover {
  background-color: var(--black);
}

/* React Transition Group Styles */
.fade-enter {
  opacity: 0;
  transform: scale(0.96);
}
.fade-exit {
  opacity: 1;
}
.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: 250ms ease-in;
  transition-property: opacity, transform;
}
.fade-exit {
  opacity: 1;
  transform: scale(0.96);
}
.fade-exit-active {
  opacity: 0;
  transform: scale(1);
  transition: 250ms ease-in;
  transition-property: opacity, transform;
}
