.about {
  transition: opacity 2s ease 0s;
}
.about--unloaded {
  opacity: 0;
}

.about__wrapper {
  padding: 10rem 0 10rem 0;
}
.top-section {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  max-width: 800px;
  position: relative;
  margin-top: 7rem;
  margin-bottom: 10rem;
}
.about .left {
  flex: 1;
  position: relative;
  max-width: 320px;
}
.about .right {
  flex: 1;
}
.about .left::after,
.about .left::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}
.about .left::before {
  top: -3%;
  right: -3%;
  background-color: var(--highlight-color);
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23DCDCDD' fill-opacity='0.2'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  animation: animatedBackground 5s linear infinite alternate;
}
.about .left::after {
  top: 3%;
  right: 3%;
  border: 2px solid var(--light-gray);
  z-index: -1;
}
.about__heading {
  font-size: 3.6rem;
  margin-bottom: 2rem;
}
.about__subheading {
  font-size: 2.2rem;
  margin-bottom: 1rem;
}
.subheading__span {
  position: relative;
  font-size: 2.5rem;
  padding: 0.5rem;
  color: var(--highlight-color);
  text-shadow: var(--highlight-text-shadow);
}
.about__info p {
  font-size: 1.6rem;
  text-align: justify;
  line-height: 1.4;
  margin-bottom: 3rem;
}
.about__info .des__container {
  max-width: 100%;
}
.about .left img {
  filter: grayscale(1) saturate(1) brightness(140%);
  object-fit: cover;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .top-section {
    display: block;
    margin-bottom: 10rem;
  }
  .about__wrapper {
    padding: 15rem 0;
  }
  .about .left {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .about .left img {
    max-height: 380px;
    filter: grayscale(1) saturate(1);
  }
  .about .left::after {
    display: none;
  }
  .about .left::before {
    top: 3%;
    right: -3%;
  }
  .about .right {
    background-color: rgba(7, 7, 7, 0.8);
    z-index: 3;
    position: absolute;
    top: -10%;
    left: 40%;
    text-align: center;
    padding: 1.5rem 1.7rem;
  }
  .about .right::before,
  .about .right::after {
    content: "";
    position: absolute;
    width: 102%;
    height: 102%;
    z-index: -1;
  }
  .about .right::before {
    top: 1%;
    right: 1%;
    border: 2px solid var(--light-gray);
    opacity: 0.5;
  }
  .about .right::after {
    top: -1%;
    right: -1%;
    border: 2px solid var(--highlight-color);
  }
  .about__subheading {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }
  .about__heading {
    font-size: 2.6rem;
    margin-bottom: 1rem;
  }
  .subheading__span {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 575px) {
  .about__wrapper {
    padding: 10rem 0;
  }
  .top-section {
    margin-top: 5rem;
    margin-bottom: 2.5rem;
  }
  .about .left {
    max-width: 100%;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  }
  .about .left::before,
  .about .right::before,
  .about .right::after {
    display: none;
  }
  .about .left img {
    max-height: 100%;
  }
  .about .right {
    backdrop-filter: blur(2px);
    background-color: rgba(7, 7, 7, 0.8);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 2rem 3rem;
    border: 2px solid var(--highlight-color);
  }
  .about__subheading {
    font-size: 2.2rem;
  }
  .about__heading {
    font-size: 2.8rem;
    margin-bottom: 1rem;
  }
  .subheading__span {
    font-size: 2.4rem;
  }
  .about__info p {
    font-size: 1.8rem;
    text-align: justify;
    line-height: 1.6;
  }
}
@media only screen and (max-width: 479px) {
  .about .right {
    padding: 1.6rem 2rem;
    justify-content: space-between;
  }
  .about__subheading {
    font-size: 2rem;
  }
  .about__heading {
    font-size: 2.4rem;
    margin-bottom: 1rem;
  }
  .subheading__span {
    font-size: 2.2rem;
  }
  .about__info p {
    font-size: 1.6rem;
    text-align: justify;
    line-height: 1.6;
  }
}
@media only screen and (max-width: 380px) {
  .about__subheading {
    font-size: 1.8rem;
  }
  .subheading__span {
    font-size: 2rem;
  }
  .about__info p {
    font-size: 1.4rem;
    text-align: justify;
    line-height: 1.2;
  }
}
