.title-section {
  text-align: center;
}

.title-section__subtitle {
  opacity: 0.8;
  font-family: "Roboto Flex", sans-serif;
  font-size: 3.2rem;
}

.title-section__title {
  font-family: "Prompt", sans-serif;
  font-size: 6rem;
  margin-top: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
}

.title-section__title:before {
  content: "";
  background-color: var(--highlight-color);
  position: absolute;
  left: 0;
  bottom: 3px;
  width: 100%;
  height: 5px;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.title-section__title:hover::before,
.scrolled .title-section__title:before {
  bottom: 0;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .title-section__subtitle {
    font-size: 2rem;
  }

  .title-section__title {
    font-size: 4.5rem;
  }
}
